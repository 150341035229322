// import Seo from "gatsby-plugin-wpgraphql-seo"
//import { Head as HeadSeo } from "../components/head"
import "../css/gutenberg.css"
import "moment/locale/de"
import "twin.macro"
import "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BackButton from "../components/back-button"
import Container from "../components/container"
import parse from "html-react-parser"
import React from "react"
import ReactHtmlParser from 'react-html-parser'

const Organisation = ({ data: { organisationen } }) => {
  return (
    <div tw="mb-20">
      {/* <Seo post={pageReplaced} /> */}
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>
        <div tw="mb-10 lg:mb-10">
          {organisationen.title && (
            <h1 tw="max-w-3xl text-3xl font-bold lg:text-5xl">{ReactHtmlParser(organisationen.title)}</h1>
          )}
        </div>
        <div tw="grid grid-cols-12 gap-y-10 lg:gap-5">
          <div tw="relative col-span-12 lg:col-span-3 xl:col-span-2">
            {/**organisationen.organisation.category && (
              <p tw="text-xl mb-5">{organisationen.organisation.category}</p>
            )**/}

            {organisationen.organisation.homepage && (
              <a
                href={organisationen.organisation.homepage}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                Homepage
              </a>
            )}
            {organisationen.organisation.x && (
              <a
                href={organisationen.organisation.x}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                X
              </a>
            )}
            {organisationen.organisation.instagram && (
              <a
                href={organisationen.organisation.instagram}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                Instagram
              </a>
            )}
            {organisationen.organisation.linkedin && (
              <a
                href={organisationen.organisation.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                LinkedIn
              </a>
            )}
          </div>
          <div tw="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4 xl:col-start-3">
            <div className="wp-content" tw="mb-11">
              {organisationen.content && <>{parse(organisationen.content)}</>}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Organisation

export const Head = props => {
  const propsWithTitle = {
    ...props,
    data: {
      ...props.data,
      seo: {
          ...props.data.organisationen.seo,
          // title: props.data.event.title,
      },
    },
  }

  //return <HeadSeo {...propsWithTitle} />
}

//export { Head } from "../components/head"

export const pageQuery = graphql`
  query Organisation($id: String!) {
   
    organisationen: wpOrganisation(id: { eq: $id }) {
      id
      title
      status
      content
      uri
      organisation {
        instagram
        x
        homepage
        category
        linkedin
      }
      seo {
        canonical
        cornerstone
        focuskw
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphSiteName
        opengraphPublisher
        opengraphTitle
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
      }
    }
  }
`
